import { render, staticRenderFns } from "./comp_DynamicPageItems.vue?vue&type=template&id=5ffc653a&scoped=true&"
import script from "./comp_DynamicPageItems.vue?vue&type=script&lang=js&"
export * from "./comp_DynamicPageItems.vue?vue&type=script&lang=js&"
import style0 from "./comp_DynamicPageItems.vue?vue&type=style&index=0&id=5ffc653a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ffc653a",
  null
  
)

export default component.exports